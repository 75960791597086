<template>
  <div class="bird_sketch">
    Bird sound sketches
  </div>
</template>

<script>
export default {
  name: 'Bird_Sketch',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>