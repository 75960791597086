<template>
  <div class="shaders">
    Shaders and other things
  </div>
</template>

<script>
export default {
  name: 'Shaders',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
